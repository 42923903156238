import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import useAuth from "../hooks/useAuth";
import { PrivateRoutes } from "./PrivateRoutes";

export const AppRouter = () => {
  const user = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        {user ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
          <Route path="login" element={<LoginPage />} />
        )}

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
