import Show from "./Show";

class ShowMonth {
    month: string;
    shows: Show[];
    constructor(month: string, shows: Show[]) {
        this.month = month;
        this.shows = shows;
    }
}

export default ShowMonth;