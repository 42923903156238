class Show {
    id: string;
    title: string;
    date: string;
    address1: string;
    cityState: string;
    details: string;
    addedMoney: number;
    isCancelled: boolean;
    constructor(showPayload: any
    ) {
        this.id = showPayload[0] + showPayload[1];
        this.title = showPayload[0];
        this.date = showPayload[1];
        this.address1 = showPayload[2];
        this.cityState = showPayload[3];
        this.addedMoney = showPayload[4];
        this.isCancelled = showPayload[5] === 'TRUE';
        this.details = showPayload[6];;
    }
}

export default Show;