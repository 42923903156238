interface DateIconProps {
  day: number;
}

function DateIcon ({ day }: DateIconProps) {
  return (
    <img
      src={require(`../assets/dates/calendar${day}.png`)}
      alt={`calendar day ${day}`}
      className="date-icon"
    />
  );
};

export default DateIcon;
