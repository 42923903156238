import { StandingResult } from "./StandingResult";
export class ClassResults {
    class: string;
    className: string
    results : StandingResult[];
    get sortedResults() {
        return this.results.sort((a, b) => b.points - a.points);
    }
    get finalResults() {
        return this.sortedResults.map((result, index) => ({
            ...result,
            position: index + 1
        }));
    }
    constructor(classGroup: string, results: StandingResult[]) {
        this.class = classGroup;
        this.className = this.class;
        this.results = results;
    }
}