import GoogleIcon from "@mui/icons-material/Google";
import {
  LoginIconProps,
  LoginIconType,
} from "../models/components/LoginIconModel";

function LoginIcon ({ type }: LoginIconProps) {
  if (type === LoginIconType.Google) {
    return (
      <>
        <GoogleIcon className="login-icon" />
      </>
    );
  }
  return <></>;
};

export default LoginIcon;
