import { Navigate, Route, Routes } from 'react-router-dom';
import SchedulePage from '../pages/SchedulePage';
import AppBar from '../components/AppBar';
import ShowResultsPage from '../pages/ShowResultsPage';
import ShowResultPage from '../pages/ShowResultPage';
import RiderResultPage from '../pages/RiderResultPage';
import StandingsPage from '../pages/StandingsPage';

export const PrivateRoutes = () => {
  return (
    <>
      <AppBar />
      <Routes>
        <Route path="/" element={<SchedulePage />} />
        <Route path="/showResults" element={<ShowResultsPage />} />
        <Route path="/showResults/:showId" element={<ShowResultPage />} />
        <Route path="/standings" element={<StandingsPage />} />
        {/* <Route path="/riderResults" element={<RiderResultPage />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};
