import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDO2UEt4M0Mfby0mglkIk8tPG7jfZFOvto",
    authDomain: "ezrunpbra.firebaseapp.com",
    databaseURL: "https://ezrunpbra.firebaseio.com",
    projectId: "ezrunpbra",
    storageBucket: "ezrunpbra.appspot.com",
    messagingSenderId: "61367043216",
    appId: "1:61367043216:web:21b1f692f1d025b4a80415"
  };
  
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signOut = () => auth.signOut();
export default firebase;
  