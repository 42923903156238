import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#00897b',
        },
        secondary: {
            main: '#f50057',
        }
    }
});