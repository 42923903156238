import Button from "@mui/material/Button";

interface LinkButtonProps {
  link: string;
  label: string;
  className: string;
}
const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

function LinkButton({
  link,
  label,
  className,
}: LinkButtonProps) {
  return (
    <Button
      onClick={() => openInNewTab(link)}
      className={className}
      variant="outlined"
    >
      {label}
    </Button>
  );
};

export default LinkButton;
