export class StandingResult {
    position: number;
    name: string;
    horse: string;
    points: number;
    class: string;
    constructor(standingPayload: any) {
        this.position = 0;
        this.class = standingPayload[0];
        this.name = standingPayload[1];
        this.horse = standingPayload[2];
        this.points = standingPayload[3];
    }
}