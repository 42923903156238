import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Container,
  Button,
  Tooltip,
} from '@mui/material';
import logo from '../assets/logo.png';
import useAuth from '../hooks/useAuth';
import MenuAction from '../models/components/MenuAction';
import { signOut } from '../firebaseSetup';
import { useNavigate } from 'react-router-dom';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = useAuth();
  const navigate = useNavigate();

  const settings = [new MenuAction('Logout', () => signOut())];
  const pages = [
    new MenuAction('Schedule', () => navigateTo('/schedule')),
    new MenuAction('Show Results', () => navigateTo('/showResults')),
    new MenuAction('Standings', () => navigateTo('/standings')),
    // new MenuAction('Rider Results', () => navigateTo('/riderResults'), true),
  ];

  const navigateTo = (path: string) => {
    navigate(path);
    handleCloseNavMenu();
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 40,
              width: 40,
              display: { xs: 'none', md: 'flex' },
              mr: 1,
            }}
            src={logo}
            alt="logo"
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={page.action}>
                  <Typography textAlign="center">{page.name}</Typography>
                  {page.isPremium && (
                    <StarPurple500Icon className="premium-star" />
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Container
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'center',
              mr: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 40,
                width: 40,
              }}
              src={logo}
              alt="logo"
            />
          </Container>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <MenuItem key={page.name} onClick={page.action}>
                <Typography textAlign="center">{page.name}</Typography>
                {page.isPremium && (
                  <StarPurple500Icon className="premium-star" />
                )}
              </MenuItem>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user?.displayName && user?.photoURL ? (
                  <Avatar
                    alt={user?.displayName || ''}
                    src={user?.photoURL || ''}
                  />
                ) : (
                  <Avatar />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={setting.action}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
