import Grid from "@mui/material/Unstable_Grid2";
import Show from "../models/Show";
import moment from "moment";
import DateIcon from "./DateIcon";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Chip } from "@mui/material";

interface ScheduledShowRowProps {
  show: Show;
  onRowClick: Function;
}

function renderCancelledChip(isCancelled: boolean) {
  return isCancelled ? <Chip color="error" label="Show Cancelled"  variant="outlined" className="center show-cancelled-chip"/> : null;
}

function renderAddedMoneyChip(addedMoney: number) {
  return addedMoney ? <Chip color="success" label={`${addedMoney} Added Money`}  variant="outlined" className="center show-cancelled-chip"/> : null;
}

function ScheduledShowRow({show, onRowClick}: ScheduledShowRowProps) {
  return (
    <>
      <div className="card-row" onClick={onRowClick(show)} key={show.id}>
        <Grid container spacing={2}>
          <Grid
            xs={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <DateIcon day={moment(show.date).date()} />
          </Grid>
          <Grid xs={10}>
            <div>{show.title}</div>
            <div>{moment(show.date).format("LLLL")}</div>
            {!show.isCancelled && (
              <>
                <div>{show.address1}</div>
                <div>
                  {show.cityState}
                </div>
              </>
          )}
          </Grid>
        </Grid>
        {renderCancelledChip(show.isCancelled)}
        {renderAddedMoneyChip(show.addedMoney)}
      </div>
    </>
  );
};

export default ScheduledShowRow;