class PastShow {
    id: string;
    title: string;
    date: string;
    constructor(showPayload: any
    ) {
        const values = showPayload.properties.title.split(' | ');
        this.title = values[0];
        this.date = values[1];
        this.id = showPayload.properties.title;
    }
}

export default PastShow;