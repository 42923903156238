export default class MenuAction {
    constructor(
        public readonly name: string,
        public readonly action: () => void,
        public readonly isPremium: boolean = false
    ) { 
        this.name = name;
        this.action = action;
        this.isPremium = isPremium;
    }
}