import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import packageJson from "../../package.json";

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle1">
              <CopyrightIcon sx={{ mb: "-6px" }} />
              {new Date().getFullYear()} |
              <Link href="https://obrienwritescode.com" sx={{ ml: 1 }}>
                obrienwritescode.com
              </Link>{" "}
              |
              <Typography component="span" sx={{ ml: 1 }}>
                {packageJson.version}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
