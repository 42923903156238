import { Box, Typography, Container, Button } from "@mui/material";
import LoginIcon from "../components/LoginIcon";
import { LoginIconType } from "../models/components/LoginIconModel";
import { signInWithGoogle } from "../firebaseSetup";
import { useEffect } from "react";
import Config from '../models/Config'; 

function LoginPage() {
  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Login`
  }, [])
  return (
    <>
      <Container maxWidth="md" className="text-align-center">
        <Typography component="h1" variant="h3">
          Welcome to the
        </Typography>
        <Typography component="h1" variant="h3">
          EZ Run PBRA
        </Typography>
      </Container>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Button onClick={signInWithGoogle}>
            <LoginIcon type={LoginIconType.Google} /> Sign in with google
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default LoginPage;
