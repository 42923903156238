import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/App.css";
import { AppRouter } from "./routes/AppRouter";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <div className="main-application">
        <div className="pages-content">
          <AppRouter />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
